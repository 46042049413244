import React, { forwardRef, useEffect, useState, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { TreeView as MuiTreeView } from "@mui/x-tree-view/TreeView";
import {
  TreeItem as MuiTreeItem,
  useTreeItem,
} from "@mui/x-tree-view/TreeItem";
import { Box, Tooltip, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import { EntityApi } from "@unity/components";
import { checkArray } from "../utils/checkArray";
import config from "../config";
import { useWindowSize } from "../utils/useWindowSize";

const CustomContent = forwardRef(function CustomContent(props, ref) {
  const { selected, onClick, url, icon, label, nodeId, expansionIcon } = props;

  const { handleExpansion } = useTreeItem(nodeId);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        bgcolor: selected(url) ? "#e0e0e0" : "transparent",
        cursor: "pointer",
        overflow: "hidden",
        "&:hover": {
          bgcolor: "#eeeeee",
        },
      }}
      onClick={(e) => {
        if (!selected(url)) {
          onClick(url);
        }
        handleExpansion(e);
      }}
      ref={ref}
    >
      {expansionIcon}
      {icon}
      <Tooltip title={label} placement="right">
        <Typography
          variant="body2"
          sx={{ fontWeight: "inherit", whiteSpace: "nowrap" }}
        >
          {label}
        </Typography>
      </Tooltip>
    </Box>
  );
});

const TreeItem = forwardRef(function TreeItem(props, ref) {
  return (
    <MuiTreeItem
      ContentComponent={CustomContent}
      ContentProps={{
        onClick: props.onClick,
        url: props.url,
        selected: props.selected,
      }}
      ref={ref}
      {...props}
    />
  );
});

const TreeSection = ({ id, onClick, path, selected, update }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let res = await EntityApi.getEntityByType(
        config.entityIntranetTypeId,
        id
      );
      if (res && res.status === 200) {
        setData(res.data.data);
      } else {
        setData([]);
      }
    };
    if (id) getData();
  }, [id, update]);

  return checkArray(data)
    ? data.map((el) => (
        <TreeItem
          icon={el.children ? <FolderIcon /> : <InsertDriveFileIcon />}
          key={el._id}
          nodeId={el._id}
          label={el.name}
          onClick={onClick}
          url={
            el.children
              ? `${path}/${el._id}&${el.name}`
              : `${path}/file/${el._id}&${el.name}`
          }
          selected={selected}
        >
          {el.children ? (
            <TreeSection
              id={el._id}
              onClick={onClick}
              path={`${path}/${el._id}&${el.name}`}
              selected={selected}
              update={update}
            />
          ) : null}
        </TreeItem>
      ))
    : null;
};

export default function TreeView({ update, onItemClick, root }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const windowSize = useWindowSize();
  const ref = useRef();

  const [expanded, setExpanded] = useState(["sbu-list"]);
  const [itemHeight, setItemHeight] = useState(100);

  useEffect(() => {
    setItemHeight(windowSize.height - ref.current.offsetTop - 115);
  }, [windowSize.height]);

  useEffect(() => {
    const urlArr = url
      .split("/")
      .map((el) => el.split("&").slice(0, 1).toString())
      .slice(2);
    setExpanded(["root-node", ...urlArr]);
  }, [url]);

  const onClick = (url) => {
    history.push(url);
    if (onItemClick) {
      onItemClick();
    }
  };

  const currentSelected = (itemUrl) => itemUrl === url;

  return (
    <MuiTreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={(e, nodeIds) => {
        setExpanded(nodeIds);
      }}
      sx={{ height: itemHeight, overflow: "auto" }}
      ref={ref}
      className="tour-intranettree"
    >
      <TreeItem
        nodeId="root-node"
        label={root?.name || ""}
        icon={<FolderIcon />}
        onClick={onClick}
        url={`/intranet/${root?._id}&${root?.name}`}
        selected={currentSelected}
      >
        <TreeSection
          id={root?._id}
          onClick={onClick}
          path={`/intranet/${root?._id}&${root?.name}`}
          selected={currentSelected}
          update={update}
        />
      </TreeItem>
    </MuiTreeView>
  );
}
