import React, { useRef, useState, useEffect } from "react";
import {
  Grid as MuiGrid,
  Stack,
  Typography,
  Link,
  Tooltip,
  Box,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useRouteMatch, Link as RouterLink } from "react-router-dom";

import { checkArray } from "../utils/checkArray";
import TableRowMenu from "./tableRowMenu";
import { DateHandler } from "@unity/components";
import { useAuth } from "../services/AuthContext";
import { checkFolder } from "../utils/checkFolder";
import { useWindowSize } from "../utils/useWindowSize";

const getStyles = (props) => ({
  icon: {
    width: 50,
    height: 50,
    color: props.top_menu.background,
  },
});

export default function Grid({ data, update, onUpdate }) {
  const { url } = useRouteMatch();
  const { auth } = useAuth();
  const styles = getStyles(auth.theme);
  const windowSize = useWindowSize();
  const ref = useRef();

  const [itemHeight, setItemHeight] = useState(100);

  useEffect(() => {
    setItemHeight(windowSize.height - ref.current.offsetTop - 115);
  }, [windowSize.height]);

  const handleGetLink = (row) => {
    if (checkFolder(row)) {
      //a folder, so check children
      return url === "/intranet/index"
        ? "/intranet/sbu-list"
        : `${url}/${row._id}&${row.name}`;
    } else {
      //is file, so open doc editor
      return `${url}/file/${row._id}&${row.name}`;
    }
  };

  return (
    <Box
      ref={ref}
      sx={{ height: itemHeight, overflow: "auto", p: 1, width: "100%" }}
    >
      <MuiGrid container spacing={1} sx={{ m: 0 }}>
        {checkArray(data) ? (
          data.map((el) => (
            <MuiGrid key={el._id} item xs={6} sm={4} md={3} lg={2}>
              <Stack
                sx={{
                  p: 1,
                  "&:hover": {
                    borderRadius: 5,
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                  },
                }}
              >
                {Object.prototype.hasOwnProperty.call(el, "children") ? (
                  <FolderIcon sx={styles.icon} />
                ) : (
                  <InsertDriveFileIcon sx={styles.icon} />
                )}
                {
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <Tooltip
                      title={`${el.name}${el.extension || ""}`}
                      placement="bottom-start"
                    >
                      {checkFolder(el) ||
                      el.extension === ".xlsx" ||
                      el.extension === ".docx" ||
                      el.extension === ".doc" ||
                      el.sfdt ? (
                        <Link
                          to={handleGetLink(el)}
                          component={RouterLink}
                          noWrap
                        >
                          {`${el.name}${el.extension || ""}`}
                        </Link>
                      ) : (
                        <Typography noWrap>{`${el.name}${
                          el.extension || ""
                        }`}</Typography>
                      )}
                    </Tooltip>
                    <TableRowMenu
                      row={el}
                      update={update}
                      onUpdate={onUpdate}
                    />
                  </div>
                }
                <Typography variant="subtitle2" color="textSecondary">
                  {new DateHandler(el.updated_at).UTCToLocalShortString()}
                </Typography>
              </Stack>
            </MuiGrid>
          ))
        ) : (
          <Typography>There are no files to display</Typography>
        )}
      </MuiGrid>
    </Box>
  );
}
