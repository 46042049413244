import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVert from "@mui/icons-material/MoreVert";

import { checkFolder } from "../utils/checkFolder";
import {
  EntityApi,
  DeleteButtonComponent,
  PermissionDropdownComponent,
  useAuth,
} from "@unity/components";
import { useApiLoader } from "../services/apiLoaderContext";
import config from "../config";

export default function TableRowMenu({ row, update, onUpdate }) {
  const { setLoading, setSnackbar } = useApiLoader();
  const { auth, checkPermission } = useAuth();
  const isFolder = checkFolder(row);
  const rowType = isFolder ? "folder" : "file";
  const canUpdate = checkPermission(`update_${rowType}_${row.permission}`);
  const canCreate = checkPermission(`create_${rowType}_${row.permission}`);
  const canDelete = checkPermission(`delete_${rowType}_${row.permission}`);
  const canDownload = checkPermission(`download_${rowType}_${row.permission}`);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditName, setOpenEditName] = useState(false);
  const [openEditDescription, setOpenEditDescription] = useState(false);
  const [openEditPermission, setOpenEditPermission] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState(null);
  const [allowDelete, setAllowDelete] = useState(false);

  const [name, setName] = useState(row.name || "");
  const [description, setDescription] = useState(row.description || "");
  const [permission, setPermission] = useState(row.permission || "");

  useEffect(() => {
    const checkNoChildren = async () => {
      try {
        let res = await EntityApi.getEntityByType(
          config.entityIntranetTypeId,
          row._id
        );

        if (
          res &&
          res.status === 200 &&
          res.data?.data &&
          res.data.data.length === 0
        ) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    };
    const checkCanDelete = async () => {
      let check = null;
      if (canDelete) {
        if (isFolder) {
          const childless = await checkNoChildren();
          check = childless;
        } else {
          check = true;
        }
      } else {
        return false;
      }
      setAllowDelete(check);
    };
    checkCanDelete();
  }, [row, update, canDelete, isFolder]);

  const handleRes = (res) => {
    if (res && res.status === 200) {
      setSnackbar({
        open: true,
        message: "Success",
        success: true,
      });
    } else {
      setSnackbar({ open: true, message: res.message, success: false });
    }
    setLoading(false);
    setAnchorEl(null);
    onUpdate();
  };

  const handleEditName = async () => {
    setLoading(true);
    try {
      const data = { type_id: config.entityIntranetTypeId, entity: { name } };
      let res = await EntityApi.putEntity(row._id, data);
      handleRes(res);
    } catch (err) {
      console.log(err);
      setSnackbar({ open: true, message: JSON.stringify(err), success: false });
    }
    setOpenEditName(false);
  };

  const handleEditDescription = async () => {
    setLoading(true);
    try {
      const data = {
        type_id: config.entityIntranetTypeId,
        entity: { description },
      };
      let res = await EntityApi.putEntity(row._id, data);
      handleRes(res);
    } catch (err) {
      console.log(err);
      setSnackbar({ open: true, message: JSON.stringify(err), success: false });
    }
    setOpenEditDescription(false);
  };

  const handleEditPermissions = async () => {
    setLoading(true);
    try {
      const data = {
        type_id: config.entityIntranetTypeId,
        entity: { permission },
      };
      let res = await EntityApi.putEntity(row._id, data);
      handleRes(res);
    } catch (err) {
      console.log(err);
      setSnackbar({ open: true, message: JSON.stringify(err), success: false });
    }
    setOpenEditPermission(false);
  };

  const handleDownload = () => {
    try {
      //const url = window.URL.createObjectURL(new Blob([row.base64]));
      const url = row.base64;
      const link = document.createElement("a");
      link.download = row.name;
      link.href = url;
      link.target = "_self";
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      setOpenDownload(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      let res = await EntityApi.deleteEntity(
        row._id,
        config.entityIntranetTypeId
      );
      if (res) {
        setLoading(false);
        setAnchorEl(null);
        setDeleteMessage({ id: row._id, ...res });
      } else {
        setSnackbar({
          open: true,
          message: "There was an error with your request",
          success: false,
        });
      }
    } catch (err) {
      console.log(err);
      setSnackbar({ open: true, message: err, success: false });
    }
    setOpenDelete(false);
  };

  const handleRestore = async () => {
    try {
      let res = await EntityApi.restoreEntity(
        deleteMessage?.id,
        config.entityIntranetTypeId
      );
      handleRes(res);
      if (res && res.status === 200) {
        setDeleteMessage(null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkNoChildren = async () => {
    try {
      let res = await EntityApi.getEntityByType(
        config.entityIntranetTypeId,
        row.id
      );
      if (
        res &&
        res.status === 200 &&
        res.data?.data &&
        res.data.data.length === 0
      ) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const checkConfirmDelete = async () => {
    if (row.permission !== "user") {
      const childless = await checkNoChildren();
      if (childless) {
        handleDelete();
      } else {
        setConfirmDelete(true);
      }
    } else {
      handleDelete();
    }
  };

  const handleCopy = () => {
    sessionStorage.setItem(
      "paste-buffer",
      JSON.stringify({ id: row?.id, action: "copy" })
    );
  };

  const handleMove = () => {
    setAnchorEl(null);
    sessionStorage.setItem(
      "paste-buffer",
      JSON.stringify({ id: row?.id, action: "move" })
    );
  };

  const checkCopy = () => {
    return sessionStorage.getItem("paste-buffer");
  };

  const handlePaste = async () => {
    try {
      const buffer = JSON.parse(sessionStorage.getItem("paste-buffer"));
      if (buffer?.action === "move") {
        handleMoveItem(buffer?.id);
      } else {
        handleCopyItem(buffer?.id);
      }
    } catch (err) {
      console.log(err);
      setSnackbar({
        open: true,
        message: "There was an error with your request",
        success: false,
      });
    }
  };

  const handleMoveItem = async (id) => {
    const res = await EntityApi.putEntity(id, {
      type_id: config.entityIntranetTypeId,
      entity: { parentId: row._id },
    });
    handleRes(res);
  };

  const handleCopyItem = async (id) => {
    const preRes = await EntityApi.getEntity(id, config.entityIntranetTypeId);
    if (preRes && preRes.status === 200) {
      const currentDate = new Date();
      const data = {
        type_id: config.entityIntranetTypeId,
        entity: {
          name: `${preRes?.data?.name}-${currentDate}`,
          permission: preRes?.data?.permission,
          ownerId: auth.id,
          parentId: row._id,
        },
      };
      if (checkFolder(preRes?.data)) {
        data.entity.children = [];
      }
      const res = await EntityApi.postEntity(data);
      handleRes(res);
    } else {
      setSnackbar({
        open: true,
        message: "There was an error with your request",
        success: false,
      });
    }
  };

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem disabled={!canCreate || !isFolder} onClick={handleCopy}>
          Copy
        </MenuItem>
        <MenuItem disabled={!canUpdate} onClick={handleMove}>
          Move
        </MenuItem>
        <MenuItem disabled={!checkCopy() || !isFolder} onClick={handlePaste}>
          Paste
        </MenuItem>
        <Divider />
        <MenuItem disabled={!canUpdate} onClick={() => setOpenEditName(true)}>
          Edit Name
        </MenuItem>
        <MenuItem
          disabled={!canUpdate}
          onClick={() => setOpenEditDescription(true)}
        >
          Edit Description
        </MenuItem>
        <MenuItem
          disabled={!canUpdate}
          onClick={() => setOpenEditPermission(true)}
        >
          Edit Permissions
        </MenuItem>
        <MenuItem
          disabled={!canDownload || isFolder || row?.base64 == null}
          onClick={() => setOpenDownload(true)}
        >
          Download
        </MenuItem>
        <MenuItem
          onClick={() => setOpenDelete(true)}
          disabled={!canDelete || !allowDelete}
          sx={{ color: "red" }}
        >
          Delete
        </MenuItem>
      </Menu>
      <Dialog
        open={openEditName}
        onClose={() => setOpenEditName(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Name</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mt: 1 }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditName(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleEditName}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditDescription}
        onClose={() => setOpenEditDescription(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Description</DialogTitle>
        <DialogContent>
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mt: 1 }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDescription(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleEditDescription}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditPermission}
        onClose={() => setOpenEditPermission(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Permissions</DialogTitle>
        <DialogContent>
          <PermissionDropdownComponent
            value={permission}
            onChange={(e) => setPermission(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditPermission(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleEditPermissions}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDownload}
        onClose={() => setOpenDownload(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Download?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDownload(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleDownload}>
            Download
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Delete?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
          <DeleteButtonComponent
            variant="contained"
            onClick={checkConfirmDelete}
          >
            Delete
          </DeleteButtonComponent>
        </DialogActions>
      </Dialog>
      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ color: "red" }}>
          You are about to delete a directory with children
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
          <DeleteButtonComponent variant="contained" onClick={handleDelete}>
            Delete
          </DeleteButtonComponent>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
        open={Boolean(deleteMessage)}
      >
        <DialogTitle>Delete Result</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{ color: deleteMessage?.status !== 200 ? "red" : "black" }}
          >
            {deleteMessage?.status === 200
              ? "success"
              : `${deleteMessage?.message}: ${deleteMessage?.errors?.error}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={deleteMessage?.status !== 200}
            variant="contained"
            onClick={handleRestore}
          >
            Restore
          </Button>
          <Button
            onClick={() => {
              setDeleteMessage(null);
              onUpdate();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
